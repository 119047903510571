import React, { useState } from 'react';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import EnquiryFormMethodology from './EnquiryFormMethodology';
import EnquiryFormDemographicsTest from './EnquiryFormDemographicsTest';
import EnquiryFormOverView from './EnquiryFormOverView';
import { useBranding } from '../../modules/Branding/reducers/getBranding';

const CombinedComponent = () => {
  const branding = useBranding();
  const [tabIndex, setTabIndex] = useState(0);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    address: '',
    companyName: '',
    address2: '',
    phoneNumber: '',
    city: '',
    state: '',
    zipCode: '',
    county: '',
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('form changing');
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    console.log('form data firstname : ');
    console.log(...formData.firstName);

    console.log('form data lastname : ');
    console.log(...formData.lastName);

    console.log('form data form data : ');
    console.log(event.target.value);
  };

  const isFormComplete = Object.values(formData).every(
    (value) => value.trim() !== '',
  );

  const onNextClick = () => {
    if (isFormComplete) {
      // Handle form submission or tab change
    }
  };

  return (
    //having sizing issues for larger screens.... stuck on medium from
    <Container sx={{ maxWidth: '100%', minWidth: '100%' }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
          flexDirection: 'row',
        }}
      >
        {/* <Container> */}

        <Grid

          sx={{ width: '5%' }}
          item
        >
          <img
            style={{
              width: '95%',
              height: 'auto',
              margin: '20px 0',
              objectFit: 'contain',
            }}
            src="/employerimg.png"
            alt="EmployerLogo"
          />
          
        </Grid>
        <Grid

          sx={{ width: '85%', marginY: 'auto' }}
          item
        >
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Introduction / Overview"             sx={{
              borderBottom: tabIndex >= 0 ? '2px solid #3787bc' : 'none', 
            }}/>
            <Tab label="Methodology" sx={{
              borderBottom: tabIndex >= 1 ? '2px solid #3787bc' : 'none', 
            }}/>
            {/* <Tab label="Contact Information" sx={{
              borderBottom: tabIndex >= 2 ? '2px solid #3787bc' : 'none', 
            }}/> */}
            <Tab label="Demographic Information" sx={{
              borderBottom: tabIndex >= 2 ? '2px solid #3787bc' : 'none', 
            }}/>
          </Tabs>
        </Grid>
        {/* </Container> */}
      </AppBar>

      <Box mt={4}>
        {tabIndex === 0 && (
          <EnquiryFormOverView
          // county={formData.county}
          // onFormChange={handleFormChange}
          />
        )}
        {tabIndex === 1 && <EnquiryFormMethodology />}
        {tabIndex === 2 && (
          <EnquiryFormDemographicsTest
            formData={formData}
            onFormChange={handleFormChange}
          />
        )}
      </Box>

      {/* 
      <Box mt={4} display="flex" justifyContent="space-around">
        {tabIndex > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setTabIndex((prev) => prev - 1)}
          >
            Back
          </Button>
        )}
        {tabIndex < 2 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setTabIndex((prev) => prev + 1)}
            // sx={{marginLeft:"auto"}}
            // sx={{marginLeft:"auto"}}
          >
            Continue
          </Button>
        )}
        {tabIndex === 2 && (
          <Button variant="contained" color="primary" onClick={onNextClick}>
            Submit
          </Button>
        )}
      </Box> */}

      {/* REWORKING BUTTONS FOR DEMO PURPOSES DELETE BELOW AND UNCOMMENT ABOVE */}

      <Container>
        <Grid item sx={{display:"flex", marginTop:"3%"}} justifyContent={tabIndex == 0 ? "flex-end" : "space-between"}>
          {/* <Box mt={4}> */}
            {tabIndex != 2 && tabIndex != 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setTabIndex((prev) => prev - 1)}
              >
                Back
              </Button>
            )}
            {tabIndex != 2 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setTabIndex((prev) => prev + 1)}
                // sx={{marginLeft:"auto"}}
                // sx={{marginLeft:"auto"}}
              >
                Continue
              </Button>
            )}
          {/* </Box>   */}

            {/* {tabIndex === 2 && (
          <Button variant="contained" color="primary" onClick={onNextClick}>
          Submit
          </Button>
          )} */}
        </Grid>
      </Container>
      {/* 
      <Box mt={4} display="flex" justifyContent="flex-end">

        {(tabIndex != 2 ) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setTabIndex((prev) => prev + 1)}
            // sx={{marginLeft:"auto"}}
            // sx={{marginLeft:"auto"}}
          >
            Continue
          </Button>
        )}
        
        {tabIndex === 2 && (
          <Button variant="contained" color="primary" onClick={onNextClick}>
            Submit
          </Button>
        )}
      </Box>

       */}
    </Container>
  );
};

export default CombinedComponent;
