import React, { useState, useRef, useEffect } from 'react';
import { AssessmentEnquiryFormRequest, SingleQuestionResponse } from '../type';
import { AssessmentDetails, Question } from '../../Assessments/type';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Paper, Typography, Grid, Container } from '@mui/material';
import RowRadioButtonsMCGroup from './RadioButtonsMCGroup';
import RowRadioButtonsNRGroup from './RadioButtonsNRGroup';
import TextEntryInput from './TextEntryInput';
import { RootStoreType } from '../../../redux/store.type';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage } from '../../../styles/Global.styles';
import usePostAssessmentEnquiryForm from '../../EnquiryForm/hooks/usePostAssessmentEnquiryForm';
import { CancelButton, SaveButton } from '../../Accounts/CreateClient/CreateClient.styles';
import translation from '../../../locales/en/translation.json';
import useGetAssessmentDetails from '../../Assessments/hooks/useGetAssessmentDetails';

const AssessmentForm = () => {
    const assessmentDetails: AssessmentDetails = {
        "id": 1,
        "uuid": "4e8b0fa7-2bc2-4e3e-8fda-9f09f6aef7ed",
        "name": "X Kwizite Employer Survey",
        "start_date": "2024-08-30",
        "end_date": "2024-10-01",
        "target_responses": 15,
        "status": "D",
        "send_email_reminders": false,
        "is_deleted": false,
        "assessment_type": {
            "id": 1,
            "uuid": "7a245a28-4f63-4c09-8d62-2881b5971c22",
            "name": "Employer of Choice",
            "is_deleted": false
        },
        "account": {
            "id": "fa8f6258-2ad9-45b5-a32c-66bf045cb71a",
            "name": "X Kwizite Industries",
            "website": "http://www.xkwizite.com",
            "city": "Jacksonville",
            "state": "AL"
        },
        "assessment_version": {
            "id": 1,
            "uuid": "0bada52f-02db-45f7-9961-40f3d2dcd2d0",
            "summary": "The Employer Survey version 1 is only 39 questions",
            "version": "1.0",
            "is_deleted": false
        },
        "questions": [
            {
                "id": 1,
                "text": "Does your company offer skills-based hiring, assessing candidates based only on skills, education, credentials, and experience needed to do the job, rather than a degree?",
                "display_order": 1,
                "is_deleted": false,
                "question_definition": {
                    "id": 1,
                    "name": "EmployerOfChoice Section 1 - Yes/No",
                    "is_scorable": true,
                    "is_required": false,
                    "question_type": "T",
                    "is_published": true,
                    "is_deleted": false,
                    //     "possible_answers": [
                    //         {
                    //             "id": 1,
                    //             "text": "Yes",
                    //             "score": "2.00",
                    //             "range": null,
                    //             "display_order": 1
                    //         },
                    //         {
                    //             "id": 2,
                    //             "text": "No",
                    //             "score": "1.00",
                    //             "range": null,
                    //             "display_order": 2
                    //         }
                    //     ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 2,
                "text": "How would you describe your company's onboarding process for new hires?",
                "display_order": 2,
                "is_deleted": false,
                "question_definition": {
                    "id": 2,
                    "name": "EmployerOfChoice Section 1 - Onboarding",
                    "is_scorable": true,
                    "is_required": false,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 3,
                            "text": "We do not have a formal onboarding process.",
                            "score": "0.00",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 4,
                            "text": "We have an informal onboarding process, but it is not consistently applied to all new hires.",
                            "score": "0.75",
                            "range": null,
                            "display_order": 2
                        },
                        {
                            "id": 5,
                            "text": "We have a structured onboarding program that introduces new hires to our company culture, expectations, and their specific roles.",
                            "score": "1.00",
                            "range": null,
                            "display_order": 3
                        },
                        {
                            "id": 6,
                            "text": "We have a comprehensive structured onboarding program that includes mentorship, regular feedback, and clear milestones for new hire success within the first three years.",
                            "score": "2.00",
                            "range": null,
                            "display_order": 4
                        }
                    ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 3,
                "text": "Does your company actively recruit from a diverse pool of applicants?",
                "display_order": 3,
                "is_deleted": false,
                "question_definition": {
                    "id": 1,
                    "name": "EmployerOfChoice Section 1 - Yes/No",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 1,
                            "text": "Yes",
                            "score": "2.00",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 2,
                            "text": "No",
                            "score": "1.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 4,
                "text": "Please estimate the percentage of employees retained for at least three years after going through your company's onboarding process?",
                "display_order": 4,
                "is_deleted": false,
                "question_definition": {
                    "id": 3,
                    "name": "EmployerOfChoice Section 1 - Employees Retained",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 7,
                            "text": "0 - 25%",
                            "score": "0.33",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 8,
                            "text": "25% - 50%",
                            "score": "0.50",
                            "range": null,
                            "display_order": 2
                        },
                        {
                            "id": 9,
                            "text": "51% - 58%",
                            "score": "2.00",
                            "range": null,
                            "display_order": 3
                        },
                        {
                            "id": 10,
                            "text": "59% - 75%",
                            "score": "3.00",
                            "range": null,
                            "display_order": 4
                        },
                        {
                            "id": 11,
                            "text": "More than 75%",
                            "score": "4.00",
                            "range": null,
                            "display_order": 5
                        }
                    ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 5,
                "text": "What percentage of employees voluntarily left (do not include retirees) the organization in the past year?",
                "display_order": 5,
                "is_deleted": false,
                "question_definition": {
                    "id": 4,
                    "name": "EmployerOfChoice Section 1 - Percentage that Left",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 12,
                            "text": null,
                            "score": "2.00",
                            "range": "0-38.49",
                            "display_order": null
                        },
                        {
                            "id": 13,
                            "text": null,
                            "score": "1.00",
                            "range": "38.5-100",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 6,
                "text": "What percentage of employees voluntarily left the organization within the first 90 days of hire?",
                "display_order": 6,
                "is_deleted": false,
                "question_definition": {
                    "id": 5,
                    "name": "EmployerOfChoice Section 1 - Percentage that left first 90d",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 14,
                            "text": null,
                            "score": "2.00",
                            "range": "0-30.49",
                            "display_order": null
                        },
                        {
                            "id": 15,
                            "text": null,
                            "score": "1.00",
                            "range": "30.5-100",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 7,
                "text": "How effectively does your company facilitate the transition of employees who are promoted internally?",
                "display_order": 7,
                "is_deleted": false,
                "question_definition": {
                    "id": 6,
                    "name": "EmployerOfChoice Section 1 - Effective Promotion Transition",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 16,
                            "text": "We do not have a formal strategy for internal promotion onboarding, which may extend the time employees remain in their current roles due to unclear promotion paths.",
                            "score": "0.00",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 17,
                            "text": "We have an informal strategy that may contribute to longer tenures in roles before promotion, as the process and expectations are not clearly defined.",
                            "score": "0.75",
                            "range": null,
                            "display_order": 2
                        },
                        {
                            "id": 18,
                            "text": "We have a clear internal promotion strategy that supports employees' transitions and typically see promotions occurring within a specific timeframe.",
                            "score": "1.00",
                            "range": null,
                            "display_order": 3
                        },
                        {
                            "id": 19,
                            "text": "We have a comprehensive strategy for internal promotion onboarding, which streamlines the process and potentially reduces the time spent in roles before promotion.",
                            "score": "2.00",
                            "range": null,
                            "display_order": 4
                        }
                    ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 8,
                "text": "What is the typical duration an employee spends in their role before receiving a promotion?",
                "display_order": 8,
                "is_deleted": false,
                "question_definition": {
                    "id": 7,
                    "name": "EmployerOfChoice Section 1 - Duration before promotion",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 20,
                            "text": "Less than 1 year",
                            "score": "2.00",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 21,
                            "text": "1 - 2 years",
                            "score": "2.00",
                            "range": null,
                            "display_order": 2
                        },
                        {
                            "id": 22,
                            "text": "3 - 4 years",
                            "score": "2.00",
                            "range": null,
                            "display_order": 3
                        },
                        {
                            "id": 23,
                            "text": "5 years of more",
                            "score": "1.00",
                            "range": null,
                            "display_order": 4
                        }
                    ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 9,
                "text": "What was your company’s Revenue per Employee (RPE) for the past year?",
                "display_order": 9,
                "is_deleted": false,
                "question_definition": {
                    "id": 8,
                    "name": "EmployerOfChoice Section 1 - Revenue per employee",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 24,
                            "text": null,
                            "score": "2.00",
                            "range": "\u003E0",
                            "display_order": null
                        },
                        {
                            "id": 25,
                            "text": null,
                            "score": "0.00",
                            "range": "=0",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 1,
                    "is_deleted": false,
                    "name": "I. Recruitment, Hiring, Onboarding, Turnover, Growth & Promotion",
                    "display_order": 1
                }
            },
            {
                "id": 10,
                "text": "Does your company provide training and development programs to support employee skill enhancement and professional growth?",
                "display_order": 1,
                "is_deleted": false,
                "question_definition": {
                    "id": 9,
                    "name": "EmployerOfChoice Section 2 - TrainingDevel Yes/No",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 26,
                            "text": "Yes",
                            "score": "5.00",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 27,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 2,
                    "is_deleted": false,
                    "name": "II. Upskilling and Skill Building",
                    "display_order": 2
                }
            },
            {
                "id": 11,
                "text": "What was the total amount invested in learning and development per employee by the company in the past year?",
                "display_order": 2,
                "is_deleted": false,
                "question_definition": {
                    "id": 10,
                    "name": "EmployerOfChoice Section 2 - Amount for Learning/Dev",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 28,
                            "text": null,
                            "score": "2.50",
                            "range": "0-500",
                            "display_order": null
                        },
                        {
                            "id": 29,
                            "text": null,
                            "score": "5.00",
                            "range": "\u003E500",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 2,
                    "is_deleted": false,
                    "name": "II. Upskilling and Skill Building",
                    "display_order": 2
                }
            },
            {
                "id": 12,
                "text": "What percentage of employees in your organization participated in training and professional development programs last year?",
                "display_order": 3,
                "is_deleted": false,
                "question_definition": {
                    "id": 11,
                    "name": "EmployerOfChoice Section 2 - Percent Participate Training",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 30,
                            "text": null,
                            "score": "5.00",
                            "range": "80-100",
                            "display_order": null
                        },
                        {
                            "id": 31,
                            "text": null,
                            "score": "2.50",
                            "range": "50.01-79.99",
                            "display_order": null
                        },
                        {
                            "id": 32,
                            "text": null,
                            "score": "2.00",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 2,
                    "is_deleted": false,
                    "name": "II. Upskilling and Skill Building",
                    "display_order": 2
                }
            },
            {
                "id": 13,
                "text": "How many hours per employee were spent in training or professional development programs last year?",
                "display_order": 4,
                "is_deleted": false,
                "question_definition": {
                    "id": 12,
                    "name": "EmployerOFChoice Section 2 - HoursSpendTraining",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 33,
                            "text": null,
                            "score": "5.00",
                            "range": "\u003E59.99",
                            "display_order": null
                        },
                        {
                            "id": 34,
                            "text": null,
                            "score": "2.50",
                            "range": "\u003C60",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 2,
                    "is_deleted": false,
                    "name": "II. Upskilling and Skill Building",
                    "display_order": 2
                }
            },
            {
                "id": 14,
                "text": "Does your company seek workers’ input on needed and desired benefits, such as paid leave, health insurance, and a retirement plan?",
                "display_order": 1,
                "is_deleted": false,
                "question_definition": {
                    "id": 13,
                    "name": "EmployerOfChoice Section 3 - Yes/No",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 35,
                            "text": "Yes",
                            "score": "1.36",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 36,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 15,
                "text": "What percentage of employees are enrolled in the company's health insurance plan?",
                "display_order": 2,
                "is_deleted": false,
                "question_definition": {
                    "id": 14,
                    "name": "EmployerOfChoice Section 3 - Split at 70%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 37,
                            "text": null,
                            "score": "1.36",
                            "range": "70.01-100",
                            "display_order": null
                        },
                        {
                            "id": 38,
                            "text": null,
                            "score": "0.68",
                            "range": "0-70",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 16,
                "text": "What percentage of health insurance premiums is covered by the employer's contribution?",
                "display_order": 3,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 17,
                "text": "What percentage of employees are enrolled in the company's dental insurance plan?",
                "display_order": 4,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 18,
                "text": "What percentage of dental insurance premiums is covered by the employer's contribution?",
                "display_order": 5,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 19,
                "text": "What percentage of employees are enrolled in the company's life insurance plan?",
                "display_order": 6,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 20,
                "text": "What percentage of employees are enrolled in the company's short-term disability insurance?",
                "display_order": 7,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 21,
                "text": "What percentage of life insurance premiums is covered by the employer's contribution?",
                "display_order": 8,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 22,
                "text": "What percentage of short term disability insurance premiums is covered by the employer's contribution?",
                "display_order": 9,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 23,
                "text": "What percentage of employees are enrolled in the company's long-term disability insurance?",
                "display_order": 10,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 24,
                "text": "What percentage of long term disability insurance premiums is covered by the employer's contribution?",
                "display_order": 11,
                "is_deleted": false,
                "question_definition": {
                    "id": 15,
                    "name": "EmployerOfChoice Section 3 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 39,
                            "text": null,
                            "score": "1.36",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 40,
                            "text": null,
                            "score": "0.68",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 3,
                    "is_deleted": false,
                    "name": "III. Benefits",
                    "display_order": 3
                }
            },
            {
                "id": 25,
                "text": "Does your company offer a retirement savings plan like a 401(k)?",
                "display_order": 1,
                "is_deleted": false,
                "question_definition": {
                    "id": 16,
                    "name": "EmployerOfChoice Section 4 - Yes/No",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 41,
                            "text": "Yes",
                            "score": "1.87",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 42,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 4,
                    "is_deleted": false,
                    "name": "IV. Worker Financial Wellness",
                    "display_order": 4
                }
            },
            {
                "id": 26,
                "text": "What percentage of employees participated in the company’s Retirement Savings Plan last year?",
                "display_order": 2,
                "is_deleted": false,
                "question_definition": {
                    "id": 17,
                    "name": "EmployerOfChoice Section 4 - split at 50%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 43,
                            "text": null,
                            "score": "1.88",
                            "range": "50.01-100",
                            "display_order": null
                        },
                        {
                            "id": 44,
                            "text": null,
                            "score": "0.94",
                            "range": "0-50",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 4,
                    "is_deleted": false,
                    "name": "IV. Worker Financial Wellness",
                    "display_order": 4
                }
            },
            {
                "id": 27,
                "text": "Does your company make nonelective retirement plan contributions for each eligible participant?",
                "display_order": 3,
                "is_deleted": false,
                "question_definition": {
                    "id": 16,
                    "name": "EmployerOfChoice Section 4 - Yes/No",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 41,
                            "text": "Yes",
                            "score": "1.87",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 42,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 4,
                    "is_deleted": false,
                    "name": "IV. Worker Financial Wellness",
                    "display_order": 4
                }
            },
            {
                "id": 28,
                "text": "What is your employer’s 401(k) match contribution amount in percentage format?",
                "display_order": 4,
                "is_deleted": false,
                "question_definition": {
                    "id": 18,
                    "name": "EmployerOfChoice Section 4 - split at 3%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 45,
                            "text": null,
                            "score": "1.88",
                            "range": "3-100",
                            "display_order": null
                        },
                        {
                            "id": 46,
                            "text": null,
                            "score": "0.94",
                            "range": "0-2.99",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 4,
                    "is_deleted": false,
                    "name": "IV. Worker Financial Wellness",
                    "display_order": 4
                }
            },
            {
                "id": 29,
                "text": "Does your company offer a profit-sharing program?",
                "display_order": 5,
                "is_deleted": false,
                "question_definition": {
                    "id": 16,
                    "name": "EmployerOfChoice Section 4 - Yes/No",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 41,
                            "text": "Yes",
                            "score": "1.87",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 42,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 4,
                    "is_deleted": false,
                    "name": "IV. Worker Financial Wellness",
                    "display_order": 4
                }
            },
            {
                "id": 30,
                "text": "Does your company offer a performance-based bonus incentive to all eligible employees?",
                "display_order": 6,
                "is_deleted": false,
                "question_definition": {
                    "id": 16,
                    "name": "EmployerOfChoice Section 4 - Yes/No",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 41,
                            "text": "Yes",
                            "score": "1.87",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 42,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 4,
                    "is_deleted": false,
                    "name": "IV. Worker Financial Wellness",
                    "display_order": 4
                }
            },
            {
                "id": 31,
                "text": "Does your company provide tuition assistance and/or support for student-loan repayment?",
                "display_order": 7,
                "is_deleted": false,
                "question_definition": {
                    "id": 16,
                    "name": "EmployerOfChoice Section 4 - Yes/No",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 41,
                            "text": "Yes",
                            "score": "1.87",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 42,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 4,
                    "is_deleted": false,
                    "name": "IV. Worker Financial Wellness",
                    "display_order": 4
                }
            },
            {
                "id": 32,
                "text": "Excluding Executive Compensation, what was the average percentage wage increase in the company last year?",
                "display_order": 8,
                "is_deleted": false,
                "question_definition": {
                    "id": 19,
                    "name": "EmployerOfChoice Section 4 - split at 4%",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "NR",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 47,
                            "text": null,
                            "score": "1.87",
                            "range": "4-100",
                            "display_order": null
                        },
                        {
                            "id": 48,
                            "text": null,
                            "score": "0.93",
                            "range": "0-3.99",
                            "display_order": null
                        }
                    ]
                },
                "question_category": {
                    "id": 4,
                    "is_deleted": false,
                    "name": "IV. Worker Financial Wellness",
                    "display_order": 4
                }
            },
            {
                "id": 33,
                "text": "Does your company provide workers with a meaningful voice, allowing them to contribute input to improve processes or the workplace?",
                "display_order": 1,
                "is_deleted": false,
                "question_definition": {
                    "id": 20,
                    "name": "EmployerOfChoice Section 5 - Yes(3.33)/No(1.67)",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 49,
                            "text": "Yes",
                            "score": "3.33",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 50,
                            "text": "No",
                            "score": "1.67",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 5,
                    "is_deleted": false,
                    "name": "V. Employee Support and Working Environment",
                    "display_order": 5
                }
            },
            {
                "id": 34,
                "text": "Does your company provide workplace wellness and support services like counseling or wellness programs?",
                "display_order": 2,
                "is_deleted": false,
                "question_definition": {
                    "id": 20,
                    "name": "EmployerOfChoice Section 5 - Yes(3.33)/No(1.67)",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 49,
                            "text": "Yes",
                            "score": "3.33",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 50,
                            "text": "No",
                            "score": "1.67",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 5,
                    "is_deleted": false,
                    "name": "V. Employee Support and Working Environment",
                    "display_order": 5
                }
            },
            {
                "id": 35,
                "text": "Does your company provide childcare spaces or subsidies?",
                "display_order": 3,
                "is_deleted": false,
                "question_definition": {
                    "id": 20,
                    "name": "EmployerOfChoice Section 5 - Yes(3.33)/No(1.67)",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 49,
                            "text": "Yes",
                            "score": "3.33",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 50,
                            "text": "No",
                            "score": "1.67",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 5,
                    "is_deleted": false,
                    "name": "V. Employee Support and Working Environment",
                    "display_order": 5
                }
            },
            {
                "id": 36,
                "text": "Does your organization regularly assess working conditions and promptly address safety issues to ensure workers’ continued safety?",
                "display_order": 4,
                "is_deleted": false,
                "question_definition": {
                    "id": 21,
                    "name": "EmployerOfChoice Section 5 - Yes(3.33)/No(0)",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 51,
                            "text": "Yes",
                            "score": "3.33",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 52,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 5,
                    "is_deleted": false,
                    "name": "V. Employee Support and Working Environment",
                    "display_order": 5
                }
            },
            {
                "id": 37,
                "text": "What is your company’s Days Away, Restricted, or Transferred (DART) incident rate?",
                "display_order": 5,
                "is_deleted": false,
                "question_definition": {
                    "id": 20,
                    "name": "EmployerOfChoice Section 5 - Yes(3.33)/No(1.67)",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 49,
                            "text": "Yes",
                            "score": "3.33",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 50,
                            "text": "No",
                            "score": "1.67",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 5,
                    "is_deleted": false,
                    "name": "V. Employee Support and Working Environment",
                    "display_order": 5
                }
            },
            {
                "id": 38,
                "text": "Does your company offer flexible work arrangements?",
                "display_order": 6,
                "is_deleted": false,
                "question_definition": {
                    "id": 20,
                    "name": "EmployerOfChoice Section 5 - Yes(3.33)/No(1.67)",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 49,
                            "text": "Yes",
                            "score": "3.33",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 50,
                            "text": "No",
                            "score": "1.67",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 5,
                    "is_deleted": false,
                    "name": "V. Employee Support and Working Environment",
                    "display_order": 5
                }
            },
            {
                "id": 39,
                "text": "Please confirm that your organization will use the \"Are We Making Progress\", employee engagement survey to seek input from your workforce?",
                "display_order": 7,
                "is_deleted": false,
                "question_definition": {
                    "id": 22,
                    "name": "EmployerOfChoice Section 6 - Yes(10)/No(0)",
                    "is_scorable": true,
                    "is_required": true,
                    "question_type": "MC",
                    "is_published": true,
                    "is_deleted": false,
                    "possible_answers": [
                        {
                            "id": 53,
                            "text": "Yes",
                            "score": "10.00",
                            "range": null,
                            "display_order": 1
                        },
                        {
                            "id": 54,
                            "text": "No",
                            "score": "0.00",
                            "range": null,
                            "display_order": 2
                        }
                    ]
                },
                "question_category": {
                    "id": 6,
                    "is_deleted": false,
                    "name": "VI. Workforce Engagement Survey",
                    "display_order": 6
                }
            }
        ]
    }




    const [formData, setFormData] = useState<SingleQuestionResponse[]>([]);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const { error, loading } = useGetAssessmentDetails(id || '');
    // const assessmentDetails: AssessmentDetails | null = useSelector(
    //     (state: RootStoreType) => state.assessmentReducers.getAssessmentDetailsReducer.assessment_details
    // );

    const questions = assessmentDetails?.questions || [];

    // This needs to be updated to call V2 Assessment Answers Upload
    const { postSurvey } = usePostAssessmentEnquiryForm();

    // Determine if Questions are Answered or Not and Force an Answer to Submit
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [areAllRequiredQuestionsAnswered, setAreAllRequiredQuestionsAnswered] = useState(false);
    const addToRefs = (el: HTMLDivElement | null, index: number) => {
        if (el) {
            questionRefs.current[index] = el;
        }
    };
    const scrollToFirstUnanswered = () => {
        // Find the first unanswered required question
        const firstUnansweredRequiredIndex = questions.findIndex(
            question =>
                question.question_definition.is_required && // Check if the question is required
                !formData.some(answer => answer.assessment_question_id === question.id) // Check if it has not been answered
        );

        // Scroll to the first unanswered required question if it exists
        if (questionRefs.current[firstUnansweredRequiredIndex]) {
            questionRefs.current[firstUnansweredRequiredIndex]?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onSubmit = () => {
        setHasSubmitted(true);

        const requiredQuestions = questions.filter(
            question => question.question_definition.is_required
        );

        const areAllRequiredAnswered = requiredQuestions.every(question =>
            formData.some(answer => answer.assessment_question_id === question.id)
        );

        if (areAllRequiredAnswered) {
            const formattedData: AssessmentEnquiryFormRequest = { responses: formData };
            postSurvey(id!, formattedData);
        } else {
            scrollToFirstUnanswered();
        }
    };

    useEffect(() => {
        if (hasSubmitted) {
            const requiredQuestions = questions.filter(
                question => question.question_definition.is_required
            );

            // Check if all required questions have been answered
            const allRequiredQuestionsAnswered = requiredQuestions.every(question =>
                formData.some(answer => answer.assessment_question_id === question.id)
            );

            setAreAllRequiredQuestionsAnswered(allRequiredQuestionsAnswered);

            if (!allRequiredQuestionsAnswered) {
                scrollToFirstUnanswered();
            }
        }
    }, [formData, questions, hasSubmitted]);

    const handleFormChange = (questionId: number, selectedAnswerId?: number, answerText?: string) => {
        setFormData(prevData => {
            const existingAnswerIndex = prevData.findIndex(answer => answer.assessment_question_id === questionId);
            if (existingAnswerIndex >= 0) {
                const updatedAnswers = [...prevData];
                updatedAnswers[existingAnswerIndex] = {
                    ...updatedAnswers[existingAnswerIndex],
                    selected_answer_id: selectedAnswerId,
                    answer: answerText,
                };
                return updatedAnswers;
            } else {
                return [
                    ...prevData,
                    { assessment_question_id: questionId, selected_answer_id: selectedAnswerId, answer: answerText }
                ];
            }
        });
    };

    const renderInput = (question: Question) => {
        switch (question.question_definition.question_type) {
            case 'MC':
                return (
                    <RowRadioButtonsMCGroup
                        question={question}
                        options={question.question_definition.possible_answers || []}
                        formData={formData}
                        onChange={handleFormChange}
                    />
                );
            case 'NR':
                return (
                    <RowRadioButtonsNRGroup
                        question={question}
                        options={question.question_definition.possible_answers || []}
                        formData={formData}
                        onChange={handleFormChange}
                    />
                );
            case 'T':
                return (
                    <TextEntryInput
                        question={question}
                        formData={formData}
                        onChange={handleFormChange}
                    />
                );
            default:
                return null;
        }
    };

    if (error?.response?.status === 404) {
        return (
            <Typography
                variant="h6"
                sx={{
                    margin: '40px auto',
                    color: '#9095a1',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    textAlign: 'center'
                }}
            >
                {"Evaluation doesn't exist"}
            </Typography>
        );
    }

    return (
        <Container maxWidth="lg">
            {loading ? (
                <CircularProgress sx={{ margin: '20vh auto' }} />
            ) : questions.length > 0 || !error ? (
                <>
                    <Typography
                        variant="h4"
                        sx={{
                            margin: '20px 0',
                            color: '#3e6d14',
                            fontFamily: 'Inter',
                            fontWeight: 900,
                            textAlign: 'center'
                        }}
                    >
                        {assessmentDetails?.name}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            margin: '0 auto 20px',
                            color: '#9095a1',
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            textAlign: 'justify',
                        }}
                    >
                        {assessmentDetails?.assessment_version?.summary || translation.demo_evaluation_survey_para}
                    </Typography>

                    <form>
                        <Grid container spacing={2}>
                            {questions.map((question, i) => (
                                <Grid item xs={12} key={question.id}>
                                    <Paper
                                        ref={(el) => addToRefs(el, i)}
                                        sx={{
                                            padding: '1rem',
                                            marginBottom: '1rem',
                                            border: '1px solid #E6E6E7',
                                            borderRadius: '5px',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        <Box>
                                            <Box
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-start',
                                                    position: 'relative',
                                                }}
                                            >
                                                <span style={{ width: '50px' }}>Q:{` ${i + 1}. `}</span>
                                                <Typography variant="body1" sx={{ flex: 10 }}>{question.text}</Typography>
                                            </Box>
                                            {renderInput(question)}
                                            {!formData.some(answer => answer.assessment_question_id === question.id) &&
                                                hasSubmitted && question.question_definition.is_required && (
                                                    <ErrorMessage
                                                        sx={{ paddingLeft: '25px', fontSize: '16px', color: "red", }}
                                                    >
                                                        {translation.this_field_is_required}
                                                    </ErrorMessage>
                                                )}
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '12px',
                                padding: '20px',
                            }}
                        >
                            {hasSubmitted && !areAllRequiredQuestionsAnswered && (
                                <Typography color="red" sx={{ marginRight: '10px' }}>
                                    You need to answer all required questions before you can submit.
                                </Typography>
                            )}
                            <SaveButton onClick={onSubmit}>
                                Submit
                            </SaveButton>
                            <CancelButton
                                onClick={() => {
                                    navigate('/log-in');
                                }}
                            >
                                Cancel
                            </CancelButton>
                        </Box>
                    </form>
                </>
            ) : (
                <Typography
                    variant="h4"
                    sx={{
                        margin: '40px auto',
                        color: '#9095a1',
                        fontFamily: 'Inter',
                        fontWeight: 900,
                        textAlign: 'center'
                    }}
                >
                    Either you have submitted the response once or this evaluation does
                    not contain any Question
                </Typography>
            )}
        </Container>
    );
};

export default AssessmentForm;
