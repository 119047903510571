import React, { useState, useRef, useEffect } from 'react';

const CategoryAnalysisContainer = () => {

    return (
        <div>
            V2 Here
        {/* -getEvaluationCategoriesAnalysisResults
        -IndividualResponseModal
        -EvaluationAnalysis
        -EvaluationAnalysisNotes */}
        </div>
    );
};

export default CategoryAnalysisContainer;
