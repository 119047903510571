import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { AssessmentEnquiryFormRequest } from '../type';
import { useState } from 'react';
import { postAssessmentEnquiryForm } from '../reducers/postAssessmentEnquiryForm';
import { useNavigate } from 'react-router-dom';

const usePostAssessmentEnquiryForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const navigate = useNavigate();

  const postSurvey = async (
    assessmentId: string,
    requestPayload: AssessmentEnquiryFormRequest,
  ) => {
    try {
      const resultAction = await dispatch(
        postAssessmentEnquiryForm({ assessmentId, requestPayload }),
      );
      if (postAssessmentEnquiryForm.fulfilled.match(resultAction)) {
        setLoading(false);
        navigate('/success-evaluation-assessment');
      }
    } catch (error) {
      setError(error);
    }finally{
      setLoading(false)
    }
  };

  return { loading, error, postSurvey };
};

export default usePostAssessmentEnquiryForm;
