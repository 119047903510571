import React, { FunctionComponent, useCallback } from 'react';
import { Box, Button, Container, Grid, Typography, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const EnquiryFormCompanySize = ({ formData, onFormChange }) => {
  // const onNextClick = useCallback(() => {
  // }, []);

  // const onBackClick = useCallback(() => {
  // }, []);

  return (
    <Container sx={{ marginTop: '3%' }}>
      <Typography variant="h4" color="primary">Company Size</Typography>
      <Box sx={{ marginTop: '3%' }}>
        <Typography variant="body1">
          To better understand the context of your survey responses, please indicate the total number of employees within your organization.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '2%' }}>
          Please select the option from the drop-down list that best represents your company&apos;s size category.
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: '3%' }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select an option</InputLabel>
            <Select label="Select an option" value={formData.companySize} onChange={onFormChange} >
              <MenuItem value={1}>Small Business: 1-50 employees</MenuItem>
              <MenuItem value={2}>Medium Business: 51 - 500 employees</MenuItem>
              <MenuItem value={3}>Large Business: 500+ employees</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* <Grid container justifyContent="space-between" sx={{ marginTop: '3%' }}>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={onBackClick}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onNextClick}>
            Next -&gt;
          </Button>
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default EnquiryFormCompanySize;
