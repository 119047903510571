import { useDispatch } from 'react-redux';
import { getAssessmentDetails } from '../reducers/getAssessmentDetails';
import { AppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

interface useGetAssessmentDetailsProps {
    loading: boolean;
    error: AxiosError | null;
}

const useGetAssessmentDetails = (
    assessmentId: string,
): useGetAssessmentDetailsProps => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<AxiosError | null>(null);

    useEffect(() => {
        const getDetails = async () => {
            try {
                const resultAction = await dispatch(getAssessmentDetails(assessmentId));
                if (getAssessmentDetails.fulfilled.match(resultAction)) {
                    setLoading(false);
                } else if (getAssessmentDetails.rejected.match(resultAction)) {
                    setLoading(false);
                    setError(resultAction.payload as AxiosError);
                }
            } catch (err) {
                setLoading(false);
                setError(err as AxiosError);
            }
        };

        getDetails();
    }, [assessmentId, dispatch]);

    return { error, loading };
};

export default useGetAssessmentDetails;
