import React, { useState, FunctionComponent, ChangeEvent } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import EnquiryFormCompanySize from './EnquiryFormCompanySize';
import EnquiryFormCountyIntake from './EnquiryFormCountyIntake';
import EnquiryFormContactForm from './EnquiryFormContactForm';
import EnquiryFormIndustryType from './EnquiryFormIndustryType';
import EnquiryFormRegionalManufacturersAssoc from './EnquiryFormRegionalManufacturersAssoc';
import EnquiryFormLegalStatement from './EnquiryFormLegalStatement';

const ContinueButton: FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => (
  <div>
    <Button variant="contained" color="primary" onClick={onClick}>
      {/* Continue -&gt; */}
      Continue
    </Button>
  </div>
);

const PreviousButton: FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => (
  <div>
    <Button variant="contained" color="primary" onClick={onClick}>
      {/* &lt;- Back */}
      Back
    </Button>
  </div>
);

interface EnquiryFormDemographicsProps {
  formData: {
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    address: string;
    companyName: string;
    address2: string;
    phoneNumber: string;
    city: string;
    state: string;
    zipCode: string;
    county: string;
  };
  onFormChange: (event: ChangeEvent<HTMLInputElement>) => void;
}


const EnquiryFormDemographicsTest: React.FC<EnquiryFormDemographicsProps> = ({ formData, onFormChange }) => {

  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <Container>
      {currentStep === 0 && ( 
        <>
          <EnquiryFormContactForm formData={formData} onFormChange={onFormChange} />
          <Grid item sx={{justifyContent:"space-between", display:"flex", marginTop:"5%"}}>
          <PreviousButton onClick={handlePreviousStep} />
          <ContinueButton onClick={handleNextStep} />
          </Grid>
        </>
      )}
      {currentStep === 1 && (
        <>
          <EnquiryFormCountyIntake />
          <Grid item sx={{justifyContent:"space-between", display:"flex", marginTop:"45%"}}>
          <PreviousButton onClick={handlePreviousStep} />
          <ContinueButton onClick={handleNextStep} />
          </Grid>
        </>
      )}
      {currentStep === 2 && (
        <>
          <EnquiryFormCompanySize formData={formData} onFormChange={onFormChange} />
          <Grid item sx={{justifyContent:"space-between", display:"flex", marginTop:"45%"}}>
            <PreviousButton onClick={handlePreviousStep} />
            <ContinueButton onClick={handleNextStep} />
          </Grid>
        </>
      )}
      {currentStep === 3 && (
        <>
          <EnquiryFormIndustryType />
          <Grid item sx={{justifyContent:"space-between", display:"flex", marginTop:"45%"}}>
            <PreviousButton onClick={handlePreviousStep} />
            <ContinueButton onClick={handleNextStep} />
          </Grid>
        </>
      )}
            {currentStep === 4 && (
        <>
          <EnquiryFormRegionalManufacturersAssoc />
          <Grid item sx={{justifyContent:"space-between", display:"flex", marginTop:"45%"}}>
            <PreviousButton onClick={handlePreviousStep} />
            <ContinueButton onClick={handleNextStep} />
          </Grid>
        </>
      )}
                  {currentStep === 5 && (
        <>
          <EnquiryFormLegalStatement />
          <Grid item sx={{justifyContent:"space-between", display:"flex", marginTop:"5%"}}>
            <PreviousButton onClick={handlePreviousStep} />
            <ContinueButton onClick={handleNextStep} />
          </Grid>
        </>
      )}
    </Container>
  );
};

export default EnquiryFormDemographicsTest;
