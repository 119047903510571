import { Box, Grid, Paper, TextField } from '@mui/material';
import React from 'react';
import {
  Typography19px600,
  Typography12px400,
  CommonTypography,
} from '../../../../styles/Global.styles';
import GaugeChart from './GaugeChart';
import DistributionGraph from './DistributionGraph';
import { SaveButton } from '../../../Accounts/CreateClient/CreateClient.styles';
import { store, useAppSelector } from '../../../../redux/store';
import { updateResultAnalysis } from '../../reducers/postEvaluationResultAnalysis';
import { useNavigate, useParams } from 'react-router-dom';
import { ResultEvaluationResponse, AggregateOverallScores, EvaluationCategoryResponse, EvaluationQuestionCategories, EvaluationResultAnalysisResponse } from '../../type';
import { EvaluationDetail } from '../../reducers/getEvaluationDetail';

import usePostEvaluationResultsAnalysis from '../../hooks/usePostEvaluationResultsAnalysis';
import usePostEvaluationCategoriesResultAnalysis from '../../hooks/usePostEvaluationCategoriesResultAnalysis';
import useGetEvaluationCategoryAnalysisResults from '../../hooks/useGetEvaluationCategoryAnalysisResults';
import useGetEvaluationQuestionCategories from '../../hooks/useGetEvaluationQuestionCategory';
import ChallengesAndOpportunitiesComponent from '../../Components/ChallengesAndOpportunities';

// V2 counterpart needs to be made for this
// -postResultsAnalysisSurvey
// -getEvaluationCategoriesAnalysisResults

interface EvaluationResponseAggregate {
  evaluationCategoryId: string;
  evaluationQuestionCategories: Array<{
    evaluationQuestionCategoryId: string;
    overall_scores: {
      [key: string]: number;
    };
  }>;
}

function replaceCategoryIdsWithNames(
  aggregate: EvaluationCategoryResponse,
  categories: EvaluationQuestionCategories[]
): EvaluationCategoryResponse {
  // Build a mapping from ID to category name
  const idToCategoryName = categories.reduce<Record<string, string>>((acc, category) => {
    acc[category.id] = category.category;
    return acc;
  }, {});

  // Copy the aggregate to avoid mutating the original data
  const newAggregate: EvaluationCategoryResponse = {
    ...aggregate,
    evaluationQuestionCategories: aggregate.evaluationQuestionCategories.map(category => ({
      ...category,
      evaluationQuestionCategoryId: idToCategoryName[category.evaluationQuestionCategoryId] || "Unknown Category",
      overall_scores: { ...category.overall_scores }  // Ensure deep copy if needed
    }))
  };

  return newAggregate;
}

const EvaluationAnalysisContainer = ({ info }: { info: EvaluationDetail }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { postResultsAnalysisSurvey } = usePostEvaluationResultsAnalysis();
  const { postEvaluationCategoriesResult } = usePostEvaluationCategoriesResultAnalysis();
  const { getEvaluationCategoriesAnalysisResults } = useGetEvaluationCategoryAnalysisResults();
  const evaluationQuestionCategoryReducer = useGetEvaluationQuestionCategories();

  const evaluationQuestionCategories = useAppSelector((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.allEvaluationReducers.getEvaluationQuestionCategoryReducer
      .evaluationQuestionCategories?.results ?? [];
  });

  React.useEffect(() => {
    evaluationQuestionCategoryReducer.getQuestionCategories();
  }, []);

  const evaluationAnalysisResponses: { [categoryId: string]: ResultEvaluationResponse } =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.postEvaluationResultsAnalysisReducer.resultEvaluationResponses;
    });

  const mapCategoryNamesToEvaluationAnalysisResponses = (
    categories: { id: string; category: string }[],
    responses: { [key: string]: ResultEvaluationResponse }
  ): { [key: string]: ResultEvaluationResponse } => {
    const categoryMapping = categories.reduce<{ [key: string]: string }>((acc, category) => {
      acc[category.id] = category.category;
      return acc;
    }, {});

    return Object.keys(responses).reduce<{ [key: string]: ResultEvaluationResponse }>((acc, key) => {
      const categoryName = categoryMapping[key];
      if (categoryName && responses[key].average_evaluation_score > 0) {
        acc[categoryName] = responses[key];
      }
      return acc;
    }, {});
  };

  const mappedEvaluationAnalysisResponses = React.useMemo(() => {
    if (evaluationQuestionCategories && evaluationAnalysisResponses) {
      return mapCategoryNamesToEvaluationAnalysisResponses(evaluationQuestionCategories, evaluationAnalysisResponses);
    }
    return {};
  }, [evaluationQuestionCategories, evaluationAnalysisResponses]);

  const categoryNames = React.useMemo(() => {
    return evaluationQuestionCategories ? evaluationQuestionCategories.reduce<{ [key: string]: string }>((acc, category) => {
      acc[category.id] = category.category;
      return acc;
    }, {}) : {};
  }, [evaluationQuestionCategories]);

  const gaugeCharts = React.useMemo(() => {
    return Object.entries(evaluationAnalysisResponses)
      .filter(([_, data]) => data.average_evaluation_score !== 0)
      .map(([categoryId, data]) => {
        const categoryName = categoryNames[categoryId] || "Unknown Category";
        return (
          <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={categoryId}>
            <GaugeChart name={categoryName} score={data.average_evaluation_score} />{/* maxScore={data.max_score} /> TODO: Add in max_score, derived from questions in category */}
          </Grid>
        );
      });
  }, [evaluationAnalysisResponses, categoryNames]);

  //Evaluation Response Aggregate by Category for an entire Evaluation Category
  const evaluationResponseAggregateByCategory: EvaluationCategoryResponse = useAppSelector((state) => {
    return state.allEvaluationReducers.postEvaluationCategoriesResultAnalysisReducer.evaluationResponseAggregateByCategory;
  });

  const updatedCategoryResponse = replaceCategoryIdsWithNames(evaluationResponseAggregateByCategory, evaluationQuestionCategories);

  const evaluationCategoryAnalysisResponses: { [categoryId: string]: EvaluationResultAnalysisResponse } =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.getEvaluationCategoryAnalysisResultsResponseReducer
        .evaluationResultResponses;
    });

  const mapCategoryNamesToResponses = (
    categories: { id: string; category: string }[],
    responses: { [key: string]: EvaluationResultAnalysisResponse }
  ): { [key: string]: EvaluationResultAnalysisResponse } => {
    const categoryMapping = categories.reduce<{ [key: string]: string }>((acc, category) => {
      acc[category.id] = category.category;
      return acc;
    }, {});

    return Object.keys(responses).reduce<{ [key: string]: EvaluationResultAnalysisResponse }>((acc, key) => {
      const categoryName = categoryMapping[key];
      if (categoryName && responses[key].number_of_questions > 0) {
        acc[categoryName] = responses[key];
      }
      return acc;
    }, {});
  };

  const mappedEvaluationCategoryAnalysisResponses = React.useMemo(() => {
    if (evaluationQuestionCategories && evaluationCategoryAnalysisResponses) {
      return mapCategoryNamesToResponses(evaluationQuestionCategories, evaluationCategoryAnalysisResponses);
    }
    return {};
  }, [evaluationQuestionCategories, evaluationCategoryAnalysisResponses]);

  const challengesAndOfferings = Object.entries(mappedEvaluationCategoryAnalysisResponses).map(([key, category]) => (
    <ChallengesAndOpportunitiesComponent
      key={key}
      title={key || "Category Name"}
      challengesThreats={category.challenges_threats || []}
      possibleOfferings={category.possible_offerings || []}
    />
  ));

  const scores = React.useMemo(() => {
    const scoresArray = Object.values(evaluationAnalysisResponses).map(response => response.average_evaluation_score);
    const total = scoresArray.reduce((acc, score) => acc + score, 0);
    const average = scoresArray.length > 0 ? total / scoresArray.length : 0;
    return { scoresArray, average };
  }, [evaluationAnalysisResponses]);

  //Aggregate Overall Scores for an entire Evaluation Category
  const aggregateOverallScores = evaluationResponseAggregateByCategory.evaluationQuestionCategories.reduce<AggregateOverallScores>((acc, category) => {
    Object.entries(category.overall_scores).forEach(([key, value]) => {
      // Ensure value is treated as a number
      const numericValue = Number(value);
      acc[key] = (acc[key] || 0) + numericValue;
    });
    return acc;
  }, {});

  const scoreMapping = {
    "Strongly Disagree": 1,
    "Disagree": 2,
    "Neutral": 3,
    "Agree": 4,
    "Strongly Agree": 5
  };

  //Calculate the Average Score for the Aggregate for the entirie Evaluation Category
  let totalScore = 0;
  let totalCount = 0;
  for (const [response, count] of Object.entries(aggregateOverallScores)) {
    totalScore += (scoreMapping[response] * count);
    totalCount += count;
  }
  const AverageScoreAggregate = totalCount > 0 ? totalScore / totalCount : 0;

  //Calculate aggregateOverallScores
  const aggregateOverallScoreMapping = {};
  Object.keys(scoreMapping).forEach(key => {
    const numericScore = scoreMapping[key];
    aggregateOverallScoreMapping[numericScore] = aggregateOverallScores[key];
  });

  interface ScoreMapping {
    [key: string]: number;
  }

  //Calculate Percentile
  function calculatePercentile(scoreMapping: ScoreMapping, score: number): number {
    let totalEntries = 0;
    let entriesBelowScore = 0;

    for (let i = 1; i <= 5; i++) {
      totalEntries += scoreMapping[i.toString()];
      if (i < score) {
        entriesBelowScore += scoreMapping[i.toString()];
      }
    }

    // If the score is a whole number, add half of the entries of that score to the count
    const scoreFloor = Math.floor(score);
    if (scoreFloor === score) {
      entriesBelowScore += scoreMapping[scoreFloor.toString()] / 2;
    }

    // Calculate percentile
    return (entriesBelowScore / totalEntries) * 100;
  }

  const percentile = calculatePercentile(aggregateOverallScoreMapping, scores.average);

  const getPercentileSuffix = (percentile) => {
    const lastDigit = percentile % 10;
    if (percentile === 11 || percentile === 12 || percentile === 13) {
      return 'th';
    }
    switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
    }
  };

  const percentileValue = percentile.toFixed(0);
  const percentileSuffix = getPercentileSuffix(percentileValue);

  function calculateAboveOrBelowAverage(score: number, averageScore: number): string {
    // Calculate the percentage difference
    const percentageDifference = ((score - averageScore) / averageScore) * 100;

    // Determine if it's above or below average
    if (percentageDifference > 0) {
      // Score is above average
      return `${percentageDifference.toFixed(0)}% above average`;
    } else if (percentageDifference < 0) {
      // Score is below average (convert to positive for readability)
      return `${Math.abs(percentageDifference).toFixed(0)}% below average`;
    } else {
      // Score is exactly average
      return `Exactly average`;
    }
  }

  const percentAverage = calculateAboveOrBelowAverage(scores.average, AverageScoreAggregate);

  React.useEffect(() => {
    if (evaluationQuestionCategories && evaluationQuestionCategories.length > 0) {
      const surveyData = evaluationQuestionCategories.map(category => ({
        evaluation_id: id!,
        question_category_id: category.id,
      }));

      postResultsAnalysisSurvey(surveyData);
    }
  }, [id, evaluationQuestionCategories]);

  React.useEffect(() => {
    if (info.evaluation_category_id && info.evaluation_category_id.trim() !== '') {
      postEvaluationCategoriesResult({
        evaluation_category_id: info.evaluation_category_id
      });
    }
  }, [info.evaluation_category_id]);

  React.useEffect(() => {
    if (evaluationQuestionCategories && evaluationQuestionCategories.length > 0) {
      const surveyData = evaluationQuestionCategories.map(category => ({
        evaluation_id: id!,
        question_category_id: category.id,
      }));

      getEvaluationCategoriesAnalysisResults(surveyData);
    }
  }, [id, evaluationQuestionCategories]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '25px',
          right: '25px',
        }}
      >
        {(info.responses_received > 0) && (
          <SaveButton
            sx={{
              paddingX: 2,
              fontSize: '18px'
            }}
            onClick={() => {
              store.dispatch(updateResultAnalysis());
              navigate(
                `/evaluation-assessment-detail/${id || ''}/evaluation-assessment-analysis-score`,
              );
            }}
          >
            {'Report Builder'}
          </SaveButton>
        )}
      </Box>
      <Grid
        item
        sx={{
          marginBottom: '10px',
        }}
      >
        <Typography19px600>{'Comparison'}</Typography19px600>
        <Typography12px400
          sx={{
            color: '#B5B5C3',
          }}
        >
          {'Where the company stands compared to others'}
        </Typography12px400>
        <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          <TextField
            size="small"
            value={percentAverage}
            style={{ pointerEvents: 'none' }}
          />
          <TextField
            size="small"
            value={`${percentileValue}${percentileSuffix} percentile`}
            style={{ pointerEvents: 'none' }}
          />
        </Box>
      </Grid>

      <Box sx={{ marginTop: '30px' }}>
        <Grid container spacing={2} justifyContent="center">
          {challengesAndOfferings}
        </Grid>

        <Grid item marginBottom={'70px'}>
          <Typography19px600>{'Summary'}</Typography19px600>
          <Typography12px400
            sx={{
              color: '#B5B5C3',
              marginBottom: '20px'
            }}
          >
            {'Brief overview of results'}
          </Typography12px400>
          <Grid container spacing={2} justifyContent="center">
            {gaugeCharts}
          </Grid>

        </Grid>
      </Box>

      <Grid item marginBottom={'70px'}>
        <Paper
          sx={{
            maxWidth: '970px',
            padding: '30px',
          }}
        >
          <CommonTypography
            sx={{
              fontSize: '20px',
              margin: '10px 0 40px 30px',
              fontWeight: '500',
            }}
          >
            {'Distribution Compared to Other Companies'}
          </CommonTypography>
          <DistributionGraph data={mappedEvaluationAnalysisResponses} evaluationAggregates={updatedCategoryResponse} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EvaluationAnalysisContainer;