import { combineReducers } from '@reduxjs/toolkit';
import getEvaluationCategoriesReducer from './getEvaluationsCategories';
import getShareableLinkReducer from './getShareableLink';
import postEvaluationSlice from './postEvaluation';
import postEvaluationListReducer from './postEvaluationList';
import accountInfoReducer, { AccountDetailInitialState } from './getAccount';
import postEvaluationSurveySlice from './postEvaluationSurvey';
import postEvaluationLinkSlice from './postEvaluationLink';
import evaluationDetailReducer, {
  EvaluationDetailInitialState,
} from './getEvaluationDetail';
import {
  GetEvaluationCategoryReducer,
  PostEvaluationListReducer,
  GetShareableLinkReducer,
} from '../type';
import { RequestStatus } from '../../../types/request';
import { deleteEvaluationReducer } from './deleteEvaluation';
import putEvaluationReducer from './putEvaluation';
import postEvaluationStatusReducer from './postEvaluationStatus';
import postEvaluationResultAnalysisReducer, {
  ResultEvaluationReducer,
} from './postEvaluationResultAnalysis';
import postEvaluationResponseBreakdownReducer, {
  EvaluationResponseBreakdownReducer,
} from './postEvaluationResponseBreakdown';
import postEvaluationScoreHistoryReducer, {EvaluationScoreHistoryReducer} from './postEvaluationScoreHistory';
import postEvaluationResultsAnalysisReducer, {
  ResultsEvaluationReducer,
} from './postEvaluationResultsAnalysis';
import postEvaluationCategoriesResultAnalysisReducer, {
  EvaluationCategoriesResultAnalysisReducer,
} from './postEvaluationCategoriesResultAnalysis';
import getEvaluationQuestionCategoryReducer, {
  EvaluationQuestionCategoryReducer,
} from './getEvaluationQuestionCategory';
import putEvaluationResultAnalysisReducer from './putEvaluationResultAnalysis';
import putEvaluationNotesReducer from './putEvaluationNotes';
import getEvaluationResultAnalysisReducer, {
  EvaluationResultAnalysisResponseReducer,
} from './getEvaluationResultAnalysis';
import getEvaluationCategoryAnalysisResultsResponseReducer, {
  EvaluationCategoryAnalysisResultsResponseReducer,
} from './getEvaluationCategoryAnalysisResults';
import getEvaluationNameReducer, { EvaluationNameReducer } from './getEvaluationName';
import getEvaluationReportReducer from './getEvaluationReport';
import getEvaluationNotesReducer, {
  EvaluationNotesReducer
} from './getEvaluationNotes';
import postEvaluationMCAnswersReducer, {
  EvaluationMCAnswersReducer
} from './postEvaluationMCAnswers';
import postEvaluationTextAnswersReducer, {
  EvaluationTextAnswersReducer
} from './postEvaluationTextAnswers';

export default combineReducers<{
  getEvaluationCategoriesReducer: GetEvaluationCategoryReducer;
  getShareableLinkReducer: GetShareableLinkReducer;
  postEvaluationSlice: { status: RequestStatus };
  postEvaluationListReducer: PostEvaluationListReducer;
  evaluationDetailReducer: EvaluationDetailInitialState;
  accountInfoReducer: AccountDetailInitialState;
  postEvaluationSurveySlice: {
    status: RequestStatus;
  };
  postEvaluationLinkSlice: {
    status: RequestStatus;
  };
  deleteEvaluationReducer: { status: RequestStatus };
  putEvaluationReducer: {
    status: RequestStatus;
  };
  postEvaluationStatusReducer: { status: RequestStatus };
  postEvaluationResultAnalysisReducer: ResultEvaluationReducer;
  postEvaluationResponseBreakdownReducer: EvaluationResponseBreakdownReducer;
  postEvaluationScoreHistoryReducer: EvaluationScoreHistoryReducer;
  getEvaluationQuestionCategoryReducer: EvaluationQuestionCategoryReducer;
  putEvaluationResultAnalysisReducer: { status: RequestStatus };
  putEvaluationNotesReducer: { status: RequestStatus };
  getEvaluationReportReducer: { status: RequestStatus };
  getEvaluationResultAnalysisReducer: EvaluationResultAnalysisResponseReducer;
  getEvaluationCategoryAnalysisResultsResponseReducer: EvaluationCategoryAnalysisResultsResponseReducer;
  postEvaluationResultsAnalysisReducer: ResultsEvaluationReducer;
  postEvaluationCategoriesResultAnalysisReducer: EvaluationCategoriesResultAnalysisReducer;
  getEvaluationNameReducer: EvaluationNameReducer;
  getEvaluationNotesReducer: EvaluationNotesReducer;
  postEvaluationMCAnswersReducer: EvaluationMCAnswersReducer;
  postEvaluationTextAnswersReducer: EvaluationTextAnswersReducer;
}>({
  getEvaluationCategoriesReducer,
  getShareableLinkReducer,
  postEvaluationSlice,
  postEvaluationListReducer,
  evaluationDetailReducer,
  accountInfoReducer,
  deleteEvaluationReducer,
  postEvaluationSurveySlice,
  postEvaluationLinkSlice,
  putEvaluationReducer,
  postEvaluationStatusReducer,
  postEvaluationResultAnalysisReducer,
  postEvaluationResponseBreakdownReducer,
  postEvaluationScoreHistoryReducer: postEvaluationScoreHistoryReducer,
  getEvaluationQuestionCategoryReducer,
  putEvaluationResultAnalysisReducer,
  putEvaluationNotesReducer,
  getEvaluationResultAnalysisReducer,
  getEvaluationCategoryAnalysisResultsResponseReducer,
  getEvaluationReportReducer,
  postEvaluationResultsAnalysisReducer,
  postEvaluationCategoriesResultAnalysisReducer,
  getEvaluationNameReducer,
  getEvaluationNotesReducer,
  postEvaluationMCAnswersReducer,
  postEvaluationTextAnswersReducer
});
