import React, { useState, useRef, useEffect } from 'react';

const AssessmentDetails = () => {

    return (
        <div>
            V2 Here
            {/* -getEvaluationDetailInfo */}

            {/* Needs New V2 Components for 
            EvaluationDetails
            - This contains a lot of components. I need to remake them to be dumb

            EvaluationAnalysisContainer (This should be a component)
                -postResultsAnalysisSurvey
                -getEvaluationCategoriesAnalysisResults
                - This is a big boy and will need to be remade with numerous reusable components
            EvaluationDetailCard
            */}
        </div>
    );
};

export default AssessmentDetails;
