import React, { FunctionComponent, useCallback, useState } from 'react';
import { Container, Grid, Button, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent, Typography, Box } from '@mui/material';
import counties from './Counties';
import { Link, useParams } from 'react-router-dom';


const EnquiryFormCountyIntake: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const demographicsPath = id ? `/evaluation/${id}/demographic` : '#';
  const overviewPath = id ? `/evaluation/${id}/overview` : '#';

  const [selectedCounty, setSelectedCounty] = useState('');

  const handleCountyChange = (event: SelectChangeEvent<string>) => {
    // const selectedValue = event.target.value;
    setSelectedCounty(event.target.value as string);
  };

  // const onNextButtonClick = useCallback(() => {
  // }, []);

  // const onBackButtonClick = useCallback(() => {

  // }, []);

  return (
    <Container>
      {/* Main Content */}
      <Grid container >

              <Typography variant="h4" color="primary" width="inherit">County</Typography>
      <Box sx={{ marginTop: '3%' }}>
        <Typography variant="body1">
          Please identify and select the county where your company is located from the drop-down list.
        </Typography>

      </Box>


        <Grid item xs={12} >
        <FormControl fullWidth variant="outlined" sx={{marginTop:"4%"}}>
                  <InputLabel id="county-select-label">Select County</InputLabel>
                  <Select
                    labelId="county-select-label"
                    id="county-select"
                    value={selectedCounty}
                    // onChange={handleCountyChange}
                    onChange={handleCountyChange}
                    label="Select County"
                  >
                    {counties.map((county) => (
                      <MenuItem key={county} value={county}>
                        {county}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
        </Grid>
      </Grid>

      {/* Buttons */}
      {/* <Grid container spacing={2} justifyContent="space-between" sx={{paddingTop:"40%"}}>
        <Grid item>
          <Button variant="outlined" color="primary" to={demographicsPath} component={Link}>
            ← Back
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" to={demographicsPath} component={Link}>
            Next →
          </Button>
        </Grid>
      </Grid> */}
      
    </Container>
  );
};

export default EnquiryFormCountyIntake;
