/* eslint-disable indent */
import React, { useState, useRef, useEffect } from 'react';

const AssessmentList = () => {

    return (
        <div>
            V2 Here
            {/* - Get all evaluations */}
        </div>
    );
};

export default AssessmentList;
