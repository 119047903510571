// counties.ts
const industryTypes = [
  "311000 - Food Manufacturing",
  "312000 - Beverage and Tobacco Product Manufacturing",
  "313000 - Textile Mills",
  "314000 - Textile Product Mills",
  "315000 - Apparel Manufacturing",
  "316000 - Leather and Allied Product Manufacturing",
  "321000 - Wood Product Manufacturing",
  "322000 - Paper Manufacturing",
  "323000 - Printing and Related Support Activities",
  "324000 - Petroleum and Coal Products Manufacturing",
  "325000 - Chemical Manufacturing",
  "326000 - Plastics and Rubber Products Manufacturing",
  "327000 - Nonmetallic Mineral Product Manufacturing",
  "331000 - Primary Metal Manufacturing",
  "332000 - Fabricated Metal Product Manufacturing",
  "333000 - Machinery Manufacturing",
  "334000 - Computer and Electronic Product Manufacturing",
  "335000 - Electrical Equipment, Appliance, and Component Manufacturing",
  "336000 - Transportation Equipment Manufacturing",
  "337000 - Furniture and Related Product Manufacturing",
  "339000 - Miscellaneous Manufacturing",
  "423510 - Metal Service Centers and Other Metal Merchant Wholesalers",
  "4841 - General Freight Trucking",
  "4842 - Specialized Freight Trucking",
  "488510 - Freight Transportation Arrangement",
  "488991 - Packing and Crating",
  "4931xx - Warehousing and Storage",
  "541330 - Engineering Services",
  "541380 - Testing Laboratories",
  "541420 - Industrial Design Services",
  "541511 - Custom Computer Programming Services",
  "541512 - Computer Systems Design Services",
  "54171x - Research and Development in the Physical, Engineering, and Life",
  "5417 - Scientific Research and Development Services",
  "541713 - Research and Development in Nanotechnology",
  "541714 - Research and Development in Biotechnology (except Nanobiotechnology)",
  "541715 - Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)",
  "541720 - Research and Development in the Social Sciences and Humanities",
  "811310 - Commercial and Industrial Machinery & Equipment (except Automotive & Electronic) Repair & Maintenance"
];

export default industryTypes;
