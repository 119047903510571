import React, { FunctionComponent, useCallback, useState } from 'react';
import { Box, Button, Container, Grid, Typography, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
import industryTypes from './IndustryTypes';

const EnquiryFormIndustryType: FunctionComponent = () => {
  const [selectedIndustry, setSelectedIndustry] = useState<string>("");

  // const onNextClick = useCallback(() => {

  // }, []);

  // const onBackClick = useCallback(() => {

  // }, []);

  const handleIndustryChange = (event: SelectChangeEvent) => {
    setSelectedIndustry(event.target.value as string);
  };


  return (
    <Container sx={{ marginTop: '3%' }}>
      <Typography variant="h4" color="primary">Industry Type</Typography>
      <Box sx={{ marginTop: '3%' }}>
        <Typography variant="body1">
          The North American Industry Classification System (NAICS) is a six-digit coding system that categorizes economic
          activity into 20 industry sectors. The NAICS is used by federal statistical agencies to classify businesses for
          collecting, analyzing, and publishing statistical data about the U.S. economy.
        </Typography>
        <Typography variant="body1" sx={{ marginTop: '2%' }}>
          Please identify your industry code category from the drop-down list.
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: '3%' }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Select an option</InputLabel>
            <Select label="Select an option" value={selectedIndustry} onChange={handleIndustryChange}> 
              {/* <MenuItem value={311000}>311000 - Food Manufacturing</MenuItem>
              <MenuItem value={312000}>312000 - Beverage and Tobacco Product Manufacturing</MenuItem>
              <MenuItem value={313000}>313000 - Textile Mills</MenuItem>
              <MenuItem value={314000}>314000 - Textile Product Mills</MenuItem>
              <MenuItem value={315000}>315000 - Apparel Manufacturing</MenuItem>
              <MenuItem value={316000}>316000 - Leather and Allied Product Manufacturing</MenuItem>
              <MenuItem value={321000}>321000 - Wood Product Manufacturing</MenuItem>
              <MenuItem value={322000}>322000 - Paper Manufacturing</MenuItem>
              <MenuItem value={323000}>323000 - Printing and Related Support Activities</MenuItem>
              <MenuItem value={324000}>324000 - Petroleum and Coal Products Manufacturing</MenuItem> */}
              {industryTypes.map((industry, index) => (
                <MenuItem key={index} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* <Grid container justifyContent="space-between" sx={{ marginTop: '3%' }}>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={onBackClick}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onNextClick}>
            Next -&gt;
          </Button>
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default EnquiryFormIndustryType;
