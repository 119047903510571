/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import {
    Form,
    FieldContainer,
    SubTitleTypography,
    TitleTypography,
} from '../CreateEvaluation.style';
import {
    Autocomplete,
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
} from '@mui/material';
import translation from '../../../../locales/en/translation.json';
import {
    CancelButton,
} from '../../../Accounts/CreateClient/CreateClient.styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
    Controller,
    FieldValues,
    useFieldArray,
    useForm,
} from 'react-hook-form';
import { CreateEvaluationType, EvaluationCategory } from '../../type';
import moment from 'moment';
import CloseIcon from '../../../../assets/svg-components/CloseIcon';
import {
    CustomButton,
    CustomCheckBox,
    ErrorMessage,
    Typography12px400,
    Typography14px400,
    Typography14px500,
    Typography18px500,
} from '../../../../styles/Global.styles';
import useGetEvaluationCategories from '../../hooks/useGetEvaluationCategories';
import { RootStoreType } from '../../../../redux/store.type';
import { AccountDetails } from '../../../Accounts/types';
import useGetClients from '../../../Accounts/hooks/useGetClients';
import useGetShareableLink from '../../hooks/useGetShareableLink';
import usePostEvaluation from '../../hooks/usePostEvaluation';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetAccountSettingsUsers from '../../hooks/useGetAccountSettingUser';
import CheckIcon from '@mui/icons-material/Check';
import { useAppSelector } from '../../../../redux/store';
import useDebounce from '../../../../hooks/useDebounce';
import { useFormLabelsTheme } from '../../../../hooks/useFormLabelsTheme';
import { useBranding } from '../../../Branding/reducers/getBranding';

const LegacyCreateEvaluation = () => {
    const formLabelsTheme = useFormLabelsTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const baseUrl = window.location.href.replace(location.pathname, '/evaluate/');
    const shareableLinkResponse = useGetShareableLink();
    const customLink = baseUrl + shareableLinkResponse;
    const { debounce } = useDebounce(1000);

    const form = useForm<FieldValues & CreateEvaluationType>({
        defaultValues: {
            closed_date: null,
            account_id: '',
            evaluation_category: '',
            evaluation_name: '',
            shareable_link: shareableLinkResponse ? customLink : '',
            target_responses: 0,
            days_before_close_date: null,
            is_auto_email_reminder_active: false,
            recipients: [{ notify_email: '' }],
        },
    });

    const {
        register,
        formState: { errors },
        control,
        watch,
        setValue,
        handleSubmit,
        clearErrors,
    } = form;

    const evaluationCategoryResponse = useGetEvaluationCategories();
    const accountsResponse = useGetClients();
    const postEvaluationReducer = usePostEvaluation();

    const [selectedAccount, setSelectedAccount] =
        React.useState<AccountDetails | null>(null);
    const evaluationId = watch('evaluation_category');
    const recipients = watch('recipients'); // This will subscribe to changes of `recipients`
    const [evaluationName, setEvaluationName] = useState<string>('');
    const [shareableLink, setShareableLink] = useState(customLink);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [accountOptions, setAccountOptions] = useState<string[]>([]);

    const evaluationCategories: EvaluationCategory[] | null = useAppSelector(
        (state) => {
            return state.allEvaluationReducers.getEvaluationCategoriesReducer
                .evaluationCategories;
        },
    );

    const companyList: AccountDetails[] = useAppSelector(
        (state: RootStoreType) => {
            return (
                state.allClientReducer.clientListReducer.accountListResponse?.results ||
                []
            );
        },
    );

    // const uuid: string = useSelector((state: RootStoreType) => {
    //   return (
    //     state.allEvaluationReducers.getShareableLinkReducer.link &&
    //     state.allEvaluationReducers.getShareableLinkReducer.link.uuid
    //   );
    // });

    useEffect(() => {
        accountsResponse.clientList();
        allAccountUsers = null;
    }, []);

    useEffect(() => {
        setAccountOptions(companyList.map((company) => company.name));
        accountOptions.unshift('');
    }, [companyList]);

    const { fields, append, remove } = useFieldArray<
        FieldValues & CreateEvaluationType
    >({
        name: 'recipients',
        control,
    });

    useEffect(() => {
        if (selectedAccount && evaluationId) {
            const nameOfAccount =
                companyList &&
                companyList.find(
                    (company) => company.id.toString() == selectedAccount.id,
                )?.name;
            const nameOfEvaluation =
                evaluationCategories &&
                evaluationCategories.find((evaluation) => evaluation.id == evaluationId)
                    ?.name;
            if (nameOfAccount && nameOfEvaluation) {
                setEvaluationName(nameOfAccount + ' ' + nameOfEvaluation);
                clearErrors('evaluation_name');
            }
        }
    }, [evaluationId, selectedAccount, setValue, clearErrors]);

    // useEffect(() => {
    //   if (uuid) {
    //     setShareableLink(shareableLink + uuid);
    //     setValue('shareable_link', shareableLink + uuid);
    //   }
    // }, [uuid]);

    useEffect(() => {
        setValue('evaluation_name', evaluationName);
    }, [evaluationName]);

    useEffect(() => {
        setValue('target_responses', recipients?.length || 0);
    }, [recipients, setValue]);

    const onFormSubmit = (data: CreateEvaluationType) => {
        setSubmitButtonClicked(true);
        if (selectedAccount) {
            const payload: CreateEvaluationType = {
                ...data,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                recipients_list: data.recipients
                    ? data.recipients
                        .filter((email_address) => email_address.notify_email !== '')
                        .map((item) => item.notify_email && item.notify_email)
                    : [],
                closed_date: moment(data.closed_date, 'MM/DD/YYYY', true).format(
                    'YYYY-MM-DD',
                ),
                shareable_link: data.shareable_link.includes('/evaluate/')
                    ? data.shareable_link.split('/evaluate/')[1]
                    : data.shareable_link,
                account_id: selectedAccount.id.toString(),
            };
            if (!postEvaluationReducer.loading) {
                postEvaluationReducer.postEvaluationDetails(payload);
            }
        }
    };

    const [inputValue, setInputValue] = React.useState('');
    const { getAccountSettingsUsers } = useGetAccountSettingsUsers();
    const branding = useBranding();
    const evaluationIsRequired: string = branding?.evaluation_property_name_translation ?? "Evaluation" + " type is required.";

    let allAccountUsers = useAppSelector((state: RootStoreType) => {
        return state.allClientReducer?.accountSettingReducer?.accountSetting;
    });

    return (
        <>
            <Box></Box>
            {evaluationCategoryResponse.error ||
                evaluationCategoryResponse.loading ||
                // shareableLinkResponse.error ||
                // shareableLinkResponse.loading ||

                !evaluationCategories ? (
                <CircularProgress sx={{ margin: '20vh 48%' }} />
            ) : (
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ThemeProvider theme={formLabelsTheme}>
                        <Grid
                            container
                            sx={{
                                flexWrap: 'wrap-reverse',
                            }}
                        >
                            <Grid item sm={7} xs={10}>
                                <TitleTypography marginBottom={'34px'}>
                                    {translation.general_info}
                                </TitleTypography>
                                <Box sx={{ marginBottom: '70px' }}>
                                    <FieldContainer>
                                        <FormLabel required>{translation.account_name}</FormLabel>
                                        <Autocomplete
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    padding: '0',
                                                },
                                            }}
                                            value={selectedAccount}
                                            onChange={(_, newAccount) => {
                                                if (newAccount) {
                                                    setSelectedAccount(newAccount);
                                                    getAccountSettingsUsers(newAccount.id.toString());
                                                }
                                            }}
                                            inputValue={inputValue}
                                            onInputChange={(_, newInputValue) => {
                                                if (!newInputValue) {
                                                    setSelectedAccount(null);
                                                }
                                                debounce(() => {
                                                    if (newInputValue !== selectedAccount?.name) {
                                                        accountsResponse.clientList({
                                                            search: newInputValue,
                                                        });
                                                    }
                                                });
                                                setInputValue(newInputValue);
                                            }}
                                            disablePortal
                                            options={companyList}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(opt, val) => {
                                                return opt.id === val.id;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Search Account By Name"
                                                />
                                            )}
                                        />

                                        {!selectedAccount && submitButtonClicked && (
                                            <ErrorMessage>
                                                {translation.account_name_is_required}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>

                                <TitleTypography marginBottom={'34px'}>
                                    {translation.configuration}
                                </TitleTypography>
                                <Box>
                                    <FieldContainer>
                                        <FormLabel required>
                                            {branding?.evaluation_property_name_translation} Type
                                        </FormLabel>
                                        <Controller
                                            name="evaluation_category"
                                            control={control}
                                            rules={{
                                                required: evaluationIsRequired,
                                            }}
                                            render={({ field }) => (
                                                <Select
                                                    size="small"
                                                    displayEmpty
                                                    defaultValue={''}
                                                    placeholder={translation.select_an_option}
                                                    {...field}
                                                >
                                                    {evaluationCategories.map((category) => {
                                                        return (
                                                            <MenuItem key={category.id} value={category.id}>
                                                                {category.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                        />
                                        {errors.evaluation_category && (
                                            <ErrorMessage>
                                                {errors.evaluation_category.message?.toString()}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>

                                <Box>
                                    <FieldContainer>
                                        <FormLabel required>
                                            Title / Name
                                        </FormLabel>
                                        <Controller
                                            name="evaluation_name"
                                            control={control}
                                            rules={{
                                                required: evaluationIsRequired,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    size="small"
                                                    {...field}
                                                    value={evaluationName}
                                                    onChange={(event) => {
                                                        field.onChange(event);
                                                        setEvaluationName(event.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.evaluation_name && (
                                            <ErrorMessage>
                                                {errors.evaluation_name.message?.toString()}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>

                                <Box marginBottom={'70px'}>
                                    <FieldContainer>
                                        <FormLabel required>{translation.close_date}</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Controller
                                                control={control}
                                                name="closed_date"
                                                rules={{ required: translation.close_date_is_required }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                height: '37px',
                                                            },
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        {errors.closed_date && (
                                            <ErrorMessage>
                                                {errors.closed_date.message?.toString()}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>
                            </Grid>
                            <Grid item sm={5} xs={10}>
                                {allAccountUsers &&
                                    allAccountUsers.filter(
                                        (user) => user.role == 'account_executive',
                                    ).length < 1 ? (
                                    <Typography14px500
                                        sx={{
                                            color: '#B5B5C3',
                                            paddingBottom: '20px',
                                        }}
                                    >
                                        {translation.no_AE_available_text}
                                    </Typography14px500>
                                ) : (
                                    allAccountUsers &&
                                    allAccountUsers
                                        .filter((user) => user.role == 'account_executive')
                                        .map((user) => {
                                            return (
                                                <React.Fragment key={user.id}>
                                                    <Box marginBottom={'10px'}>
                                                        <Typography12px400
                                                            sx={{
                                                                color: '#181C32',
                                                            }}
                                                        >
                                                            {translation.account_executive}
                                                        </Typography12px400>
                                                    </Box>

                                                    <Box
                                                        key={user.id}
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '20px',
                                                            margin: '15px 0',
                                                        }}
                                                    >
                                                        <Avatar
                                                            sx={{
                                                                height: '50px',
                                                                width: '50px',
                                                            }}
                                                        >
                                                            {user.fullname[0]}
                                                        </Avatar>
                                                        <Box>
                                                            <Typography18px500
                                                                sx={{
                                                                    color: '#464E5F',
                                                                    paddingBottom: '10px',
                                                                }}
                                                            >
                                                                {user.fullname}
                                                            </Typography18px500>
                                                            <Typography12px400
                                                                sx={{
                                                                    color: '#717986',
                                                                }}
                                                            >
                                                                Business Development Officer
                                                            </Typography12px400>
                                                        </Box>
                                                    </Box>
                                                </React.Fragment>
                                            );
                                        })
                                )}

                                {allAccountUsers &&
                                    allAccountUsers
                                        .filter((user) => user.role == 'support')
                                        .map((user, index) => {
                                            return (
                                                user.role == 'support' && (
                                                    <React.Fragment key={user.id}>
                                                        {index == 0 && (
                                                            <Box marginBottom={'10px'}>
                                                                <Typography12px400
                                                                    sx={{
                                                                        color: '#181C32',
                                                                    }}
                                                                >
                                                                    {translation.support}
                                                                </Typography12px400>
                                                            </Box>
                                                        )}

                                                        <Box
                                                            key={user.id}
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '20px',
                                                                margin: '15px 0',
                                                            }}
                                                        >
                                                            <Avatar
                                                                sx={{
                                                                    height: '50px',
                                                                    width: '50px',
                                                                }}
                                                            >
                                                                {user.fullname[0]}
                                                            </Avatar>
                                                            <Box>
                                                                <Typography18px500
                                                                    sx={{
                                                                        color: '#464e5f',
                                                                        paddingBottom: '10px',
                                                                    }}
                                                                >
                                                                    {user.fullname}
                                                                </Typography18px500>
                                                                <Typography12px400
                                                                    sx={{
                                                                        color: '#717986',
                                                                    }}
                                                                >
                                                                    Operational Manager
                                                                </Typography12px400>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                                )
                                            );
                                        })}
                            </Grid>
                        </Grid>

                        <Box marginBottom={'70px'}>
                            <TitleTypography>{translation.shareable_link}</TitleTypography>
                            <SubTitleTypography marginBottom={'25px'}>
                                {translation.evaluation_shareable_link_subtext}
                            </SubTitleTypography>

                            <FieldContainer>
                                <FormLabel>{branding?.evaluation_property_name_translation} Link</FormLabel>
                                <Controller
                                    name="shareable_link"
                                    control={control}
                                    rules={{
                                        required: 'evaluation_link_is_required',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '75%' }}
                                            {...field}
                                            value={shareableLink}
                                            onChange={(event) => {
                                                // field.onChange(event);
                                                // setShareableLink(event.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </FieldContainer>
                        </Box>

                        <Box marginBottom={'70px'}>
                            <TitleTypography>{translation.direct_recipient}</TitleTypography>
                            <SubTitleTypography marginBottom={'25px'}>
                                {translation.evaluation_direct_recipient_subtext}
                            </SubTitleTypography>
                            <FormLabel>{translation.user_email}</FormLabel>
                            {fields.map((field, index) => {
                                return (
                                    <div key={field.id}>
                                        <FieldContainer sx={{ marginBottom: '10px' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0 5px',
                                                }}
                                            >
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ width: '75%' }}
                                                    {...register(`recipients.${index}.notify_email`, {
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: translation.invalid_email,
                                                        },
                                                    })}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {index != 0 && (
                                                                    <Box
                                                                        component={'span'}
                                                                        onClick={() => remove(index)}
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </Box>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            {errors.recipients &&
                                                errors.recipients[index]?.notify_email && (
                                                    <ErrorMessage>
                                                        {errors.recipients[
                                                            index
                                                        ]?.notify_email?.message?.toString()}
                                                    </ErrorMessage>
                                                )}
                                        </FieldContainer>
                                        {index === fields.length - 1 && (
                                            <Typography14px400
                                                marginLeft={'5px'}
                                                sx={{
                                                    color: '#1C1C1C',
                                                    lineHeight: '20px',
                                                    marginLeft: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => append({ notify_email: '' })}
                                            >
                                                + Add Recipient
                                            </Typography14px400>
                                        )}
                                    </div>
                                );
                            })}
                        </Box>

                        <Box marginBottom={'70px'}>
                            <TitleTypography>{translation.notification}</TitleTypography>
                            <SubTitleTypography marginBottom={'25px'}>
                                {translation.evaluation_notification_subtext}
                            </SubTitleTypography>
                            <FieldContainer>
                                <FormLabel>{translation.target_responses}</FormLabel>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    sx={{ width: '58.5%' }}
                                    {...register(`target_responses`, {
                                        pattern: {
                                            value: /^\d*\d+$/,
                                            message: 'Only positive numbers are allowed.',
                                        },
                                    })}
                                />
                                {errors.target_responses && (
                                    <ErrorMessage>
                                        {errors.target_responses.message?.toString()}
                                    </ErrorMessage>
                                )}
                            </FieldContainer>
                        </Box>

                        <Box>
                            <TitleTypography>{translation.reminder}</TitleTypography>
                            <SubTitleTypography marginBottom={'25px'}>
                                {translation.evaluation_reminder_subtext}
                            </SubTitleTypography>
                            <FieldContainer>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CustomCheckBox></CustomCheckBox>}
                                            checkedIcon={
                                                <CustomCheckBox
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fill: 'black',
                                                            width: '14px',
                                                            height: '14px',
                                                        },
                                                    }}
                                                >
                                                    <CheckIcon />
                                                </CustomCheckBox>
                                            }
                                            size="small"
                                            {...register('is_auto_email_reminder_active')}
                                        />
                                    }
                                    sx={{
                                        fontSize: '10px',
                                    }}
                                    label={
                                        <FormLabel>{translation.send_reminder_email}</FormLabel>
                                    }
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormLabel>{translation.days_before_close_date}</FormLabel>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    sx={{ width: '58.5%' }}
                                    {...register(`days_before_close_date`, {
                                        pattern: {
                                            value: /^\d*\d+$/,
                                            message: 'Only positive numbers are allowed.',
                                        },
                                    })}
                                />
                                {errors.days_before_close_date && (
                                    <ErrorMessage>
                                        {errors.days_before_close_date.message?.toString()}
                                    </ErrorMessage>
                                )}
                            </FieldContainer>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingBottom: '20px',
                            }}
                        >
                            <CustomButton
                                sx={{
                                    width: '150px',
                                }}
                                type="submit"
                                variant="contained"
                                size="medium"
                            >
                                Save
                            </CustomButton>
                            <CancelButton
                                sx={{
                                    width: '150px',
                                    marginLeft: '12px',
                                }}
                                onClick={() => {
                                    navigate('/evaluation-assessment-list');
                                }}
                            >
                                Cancel
                            </CancelButton>
                        </Box>
                    </ThemeProvider>
                </Form>
            )}
        </>
    );
};

export default LegacyCreateEvaluation;
