import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { Question, PossibleAnswer } from '../../../modules/Assessments/type';
import { SingleQuestionResponse } from '../type';

interface RowRadioButtonsMCGroupProps {
  question: Question;
  options: PossibleAnswer[];
  formData: SingleQuestionResponse[];
  onChange: (questionId: number, selectedAnswerId?: number, answerText?: string) => void; // Use the correct onChange type
}

export default function RowRadioButtonsMCGroup({
  question,
  options,
  formData,
  onChange,
}: RowRadioButtonsMCGroupProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAnswerId = parseInt(event.target.value);
    onChange(question.id, selectedAnswerId); // Call the onChange handler passed as a prop
  };

  // Find the current answer for the question
  const currentAnswer = formData.find(answer => answer.assessment_question_id === question.id);

  return (
    <FormControl sx={{ marginTop: '20px', marginLeft: '40px' }}>
      <RadioGroup
        value={currentAnswer?.selected_answer_id?.toString() || ''}
        onChange={handleChange}
        aria-labelledby={`radio-group-label-${question.id}`}
        name={`row-radio-buttons-group-${question.id}`}
      >
        {options?.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id.toString()} // Use option id as value (converted to string)
            control={<Radio />}
            label={option.text} // Display option text
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
