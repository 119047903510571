// counties.ts
const regionalManuAssoc = [
  "Bay Area Manufacturers Association (BAMA)",
  "Big Bend Manufacturers Association (BBMA)",
  "Central Gulf Industrial Alliance (CGIA)",
  "First Coast Manufacturers Association (FCMA)",
  "Manufacturers Association of Central Florida (MACF)",
  "Manufacturers Association of the Space Coast (MASC)",
  "Manufacturing & Supply Chain Alliance of Mid Florida (MSCA)",
  "Mid-FL Regional Manufacturers Association (MRMA)",
  "Natures Coast Manufacturers Association (NCMA)",
  "Sarasota-Manatee Area Manufacturers Association (SAMA)",
  "South Florida Manufacturers Association (SFMA)",
  "Southwest Regional Manufacturers Association (SRMA)",
  "Technology Coast Manufacturing and Engineering Network (TecMEN)",
  "Treasure Coast Manufacturers Association (TCMA)",
  "Volusia Manufacturers Association (VMA)"
];

export default regionalManuAssoc;
