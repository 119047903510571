import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Label } from '@mui/icons-material';

interface EnquiryFormDemographicsProps {
  formData: {
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    address: string;
    companyName: string;
    address2: string;
    phoneNumber: string;
    city: string;
    state: string;
    zipCode: string;
    county: string;
  };
  onFormChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const EnquiryFormContactForm: React.FC<EnquiryFormDemographicsProps> = ({
  formData,
  onFormChange,
}) => {
  const { id } = useParams<{ id: string }>();
  const methodologyPath = id ? `/evaluation/${id}/methodology` : '#';
  const countyIntakePath = id ? `/evaluation/${id}/county` : '#';

  const [tabIndex, setTabIndex] = useState(0);
  // const [formData, setFormData] = useState({
  //   firstName: '',
  //   lastName: '',
  //   jobTitle: '',
  //   email: '',
  //   address: '',
  //   companyName: '',
  //   address2: '',
  //   phoneNumber: '',
  //   city: '',
  //   state: '',
  //   zipCode: '',
  // });

  const onTabChange = (event, newValue: number) => {
    setTabIndex(newValue);
  };

  // const handleChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const isFormComplete = Object.values(formData).every(
    (value) => value.trim() !== '',
  );

  const onNextClick = useCallback(() => {
    if (isFormComplete) {
      // Handle the form submission or navigation
    }
  }, [isFormComplete]);

  return (
    <Container>
      {/* <AppBar position="static">
        <Tabs value={tabIndex} onChange={onTabChange}>
          <Tab label="Introduction / Overview" />
          <Tab label="Methodology" />
          <Tab label="Contact Information" />
        </Tabs>
      </AppBar> */}

      <Box mt={4}>

        <Typography variant="h4" color="primary">
          Contact and Company Demographic Information
        </Typography>

        <Typography variant="body1" mt="3%">
          Under this section, you&apos;ll provide basic information about your
          company, including name, industry type, size, location, years in
          operation, and the relevant contact details of the person or
          department responsible for the survey participation. This helps to
          categorize responses appropriately and provides a point of contact for
          any follow-up required.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="body1" gutterBottom>
          Contact Information
        </Typography>
      </Box>

      <Grid container spacing={2} mt="3%">
        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            First Name*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="First Name*"
              fullWidth
              name="firstName"
              value={formData.firstName}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.firstName && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            Last Name*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Last Name*"
              fullWidth
              name="lastName"
              value={formData.lastName}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.lastName && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            Job Title*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Job Title*"
              fullWidth
              name="jobTitle"
              value={formData.jobTitle}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.jobTitle && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            Email*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Email*"
              fullWidth
              name="email"
              value={formData.email}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.email && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            Address*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Address*"
              fullWidth
              name="address"
              value={formData.address}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.address && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Box my="3%">
        <Typography variant="body1" >
          Company Demographic Information
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            Company Name*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Company Name*"
              fullWidth
              name="companyName"
              value={formData.companyName}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.companyName && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            Company Address*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Company Address*"
              fullWidth
              name="address2"
              value={formData.address2}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.address2 && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            Phone Number*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Phone Number*"
              fullWidth
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.phoneNumber && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            City*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="City*"
              fullWidth
              name="city"
              value={formData.city}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.city && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            State*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="State*"
              fullWidth
              name="state"
              value={formData.state}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.state && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* <Typography variant="body2" color="textSecondary">
            Zip Code*
          </Typography> */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Zip Code*"
              fullWidth
              name="zipCode"
              value={formData.zipCode}
              onChange={onFormChange}
              sx={{ width: '50%' }}
            />
            {formData.zipCode && (
              <CheckCircleOutlineIcon
                sx={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      {/* <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          component={Link}
          to={methodologyPath}
          variant="contained"
          color="primary"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={countyIntakePath}
        >
          Next
        </Button>
      </Box> */}
    </Container>
  );
};

export default EnquiryFormContactForm;
