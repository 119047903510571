import React, { useState, useRef, useEffect } from 'react';

const EditAssessment = () => {

    return (
        <div>
            V2 Here
            {/* This should call a component that is able to be used by Create Assessment and Edit Assessment */}
            {/* -getEvaluationDetailInfo */}
        </div>
    );
};

export default EditAssessment;
