import React from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Button } from '@mui/material';
import { QuestionResponse } from '../../type';
import { useNavigate } from 'react-router-dom';
import './ResultsTable.css';

interface ResultsTableProps {
  open: boolean;
  onClose: () => void;
  data: QuestionResponse[];
}

const ResultsTable: React.FC<ResultsTableProps> = ({ open, onClose, data }) => {
  const navigate = useNavigate();

  // Function to handle redirection to account detail page
  const handleDetailsClick = (accountId: string) => {
    navigate(`/account-detail-view/${accountId}`);
  };

  const toTitleCase = (str: string | undefined | null): string => {
    if (!str) return '';

    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal-box">
        <div className="table-title-row">
          {/* Bar Graph Icon */}
          <span className="svg-icon">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 16V10C7 9.44772 6.55228 9 6 9H2C1.44772 9 1 9.44772 1 10V16C1 16.5523 1.44772 17 2 17M7 16C7 16.5523 6.55228 17 6 17H2M7 16C7 16.5523 7.44772 17 8 17H12C12.5523 17 13 16.5523 13 16M7 16V6C7 5.44772 7.44772 5 8 5H12C12.5523 5 13 5.44772 13 6V16M2 17H16M13 16C13 16.5523 13.4477 17 14 17H18C18.5523 17 19 16.5523 19 16V2C19 1.44772 18.5523 1 18 1H14C13.4477 1 13 1.44772 13 2V16Z" stroke="#6D7BFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>

          <Typography className="table-title-text">
            Manufacturing Client Insights
          </Typography>

          {/* Question MArk Icon */}
          <Tooltip
            title="These Companies HAve Taken the Employee of Choice Survey"
            arrow
          >
            <span className="svg-icon">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5" clipPath="url(#clip0_138_27)">
                  <circle cx="9.99935" cy="10.0001" r="8.33333" stroke="#1E293B" strokeWidth="1.5" />
                  <path d="M8.4375 7.39575C8.4375 6.53281 9.13706 5.83325 10 5.83325C10.8629 5.83325 11.5625 6.53281 11.5625 7.39575C11.5625 7.96862 11.2542 8.46949 10.7945 8.74148C10.3984 8.97583 10 9.33135 10 9.79159V10.8333" stroke="#1E293B" strokeWidth="1.5" strokeLinecap="round" />
                  <circle cx="9.99935" cy="13.3333" r="0.833333" fill="#1E293B" />
                </g>
                <defs>
                  <clipPath id="clip0_138_27">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </Tooltip>
        </div>

        {/* Table for displaying data */}
        <TableContainer sx={{ maxHeight: 500, overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Company Size</TableCell>
                <TableCell>Answer</TableCell>
                <TableCell align="center">Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((answer: { account_id: string; company_name: string; company_size?: string; answer: string | number }, index: number) => (
                <TableRow key={answer.account_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{answer.company_name}</TableCell>
                  <TableCell>{toTitleCase(answer.company_size)}</TableCell>
                  <TableCell>{answer.answer.toString().toUpperCase()}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      size="small"
                      className="details-button"
                      onClick={() => handleDetailsClick(answer.account_id)}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default ResultsTable;
